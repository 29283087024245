<template>
	<div>
		<v-alert class="alertProgramDone" text color="transparent" v-if="isParcoursDone">
			<div class="parcoursDoneAlert">
				<div class="title-container-alert-parcours">
					<img class="image-parcours-done" src="@/assets/images/goToReport/end-parcours.png" />
					<h2 class="title">Parcours complété</h2>
				</div>
				<div class="actionContainerReport">
					<!-- <v-btn
						class="actionBtnReport"
						:to="{
							name: 'Report Parcours',
							params: { idFormation: formationProgress.formation._id, idParcours: parcours._id },
						}"
					>
						<v-icon size="medium" class="mr-1">mdi-eye-outline</v-icon>
						<b><span>Rapport</span></b>
					</v-btn> -->
					<v-btn class="actionBtnReport" @click="handleGotoReport">
						<v-icon size="medium" class="mr-1">mdi-eye-outline</v-icon>
						<b><span>Rapport</span></b>
					</v-btn>
				</div>
			</div>
		</v-alert>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ParcoursItemStatus } from '../../../../constants/parcours';

export default {
	name: 'ParcoursCompleteNotificationDiv',
	props: {
		parcours: Object,
		fromRedaction: Boolean,
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
		isParcoursDone() {
			const parcoursItemOfPropParcours = this.formationProgress.config.parcours_list.find(
				(parcoursItem) => parcoursItem.parcours_id._id === this.parcours._id
			);
			if (parcoursItemOfPropParcours == null) return false;
			if (parcoursItemOfPropParcours.status === ParcoursItemStatus.completed) return true;
			return false;
		},
		isTheLastInParcoursList() {
			const lastParcoursItem = [...this.formationProgress.config.parcours_list].sort(
				(a, b) => b.position - a.position
			)[0]; // Get last parcours item in parcours list
			return this.parcours._id === lastParcoursItem.parcours_id._id;
		},
	},
	methods: {
		handleGotoReport() {
			if (this.fromRedaction) {
				this.$router.push({
					name: 'Redaction Parcours Report',
					params: { idFormation: this.formationProgress.formation._id, idParcours: this.parcours._id },
				});
			} else
				this.$router.push({
					name: 'Report Parcours',
					params: { idFormation: this.formationProgress.formation._id, idParcours: this.parcours._id },
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.alertProgramDone {
	background-color: #f0f0fc !important;

	.title {
		font-weight: bold;
		color: rgb(104, 107, 223);
	}
}
.parcoursDoneAlert {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	h2 {
		text-align: left;
	}
	.title-container-alert-parcours {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.image-parcours-done {
		width: 70px;
		max-width: 70px;
		margin-right: 5px;
	}
}

.v-btn--is-elevated {
	box-shadow: initial;
}

.actionBtnReport {
	color: white !important;
	background-color: rgb(104, 107, 223) !important;
}

@media screen and (max-width: 600px) {
	.parcoursDoneAlert {
		display: flex;
		flex-direction: column;
		h2 {
			text-align: center;
			margin-bottom: 10px;
		}
		.title-container-alert-parcours {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.image-parcours-done {
			width: 100px;
			max-width: 100px;
			margin-right: 0px;
		}
		.actionContainerReport {
			width: 100%;
		}
		.actionBtnReport {
			width: 100%;
		}
	}
}
</style>
