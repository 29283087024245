<template>
	<div v-if="parcours">
		<ParcoursCompleteNotificationDiv :parcours="parcours" :fromRedaction="fromRedaction" />
		<div class="mb30" v-for="category in parcours.categories" :key="category._id">
			<ChapterCard :category="category" />
		</div>
	</div>
</template>

<script>
import ChapterCard from './ChapterCard';
import ParcoursCompleteNotificationDiv from '../parcours/ParcoursCompleteNotificationDiv';

export default {
	name: 'ListChapters',
	props: {
		parcours: Object,
		fromRedaction: Boolean,
	},
	components: { ChapterCard, ParcoursCompleteNotificationDiv },
};
</script>

<style lang="scss" scoped></style>
