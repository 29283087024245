<template>
	<div>
		<v-skeleton-loader height="180" type="card" class="mt-5 mb-8"></v-skeleton-loader>
		<v-skeleton-loader height="180" type="card" class="mb-8"></v-skeleton-loader>
		<v-skeleton-loader height="180" type="card" class="mb-8"></v-skeleton-loader>
	</div>
</template>

<script>
export default {
	name: 'ListChapterSketelon',
};
</script>

<style lang="scss" scoped></style>
